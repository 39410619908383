import React, { useEffect, useState } from 'react';
import '../App.css';
import logoEy from '../Images/logoEy.png'


const Sidebar = () => {
  const [activeSection, setActiveSection] = useState('');

  //Funciones para marcar en la Sidebar en seccion se encuentra al clickear o scrollear
  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'work', 'about', 'contact'];

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && isElementInViewport(element)) {
          setActiveSection(section);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const visibleTop = rect.top <= windowHeight / 2;
    const visibleBottom = rect.bottom >= windowHeight / 2;

    return visibleTop && visibleBottom;
  };

  return (
    <div className="sidebar">
      <img src={logoEy} className="App-logo" alt="logo" />
      <ul>
        <li className={activeSection === 'home' ? 'active' : ''}>
          <a href="#home">HOME</a>
        </li>
        <li className={activeSection === 'work' ? 'active' : ''}>
          <a href="#work">WORK</a>
        </li>
        <li className={activeSection === 'about' ? 'active' : ''}>
          <a href="#about">ABOUT</a>
        </li>
        <li className={activeSection === 'contact' ? 'active' : ''}>
          <a href="#contact">CONTACT</a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

