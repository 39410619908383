import React, { useState, useEffect } from 'react';
import '../App.css';

export const Work = () => {

  const imagePaths = [
    require('../Images/image1.png'),
    require('../Images/image2.png'),
    require('../Images/image3.png'),
    require('../Images/image4.png'),
    require('../Images/image5.png'),
    require('../Images/image6.png'),
    require('../Images/image7.png'),
    require('../Images/image8.png'),
    require('../Images/image9.png'),
    require('../Images/image10.png'),
    require('../Images/image11.png'),
    require('../Images/image12.png'),
  ];

  const videoPaths = [
    require('../Videos/UBA200.mp4'),
    require('../Videos/EUROLAT.mp4'),
    require('../Videos/ALERTAAEROPUERTOS.mp4'),
    require('../Videos/DEBATE.mp4'),
    require('../Videos/SENADO.mp4'),
    require('../Videos/GAMER.mp4'), // CAMBIAR GAMER
    require('../Videos/JM.mp4'),  // CAMBIAR JM
    require('../Videos/FOROMUNDIAL.mp4'),
    require('../Videos/SUPERAUTOS.mp4'),
    require('../Videos/ALEPH.mp4'),  // CAMBIAR ALEPH
    require('../Videos/JUACAS.mp4'),
    require('../Videos/PARQUESANITARIO.mp4'),
  ];


  const titles = [
    "UBA 200",
    "EUROLAT",
    "AA",
    "DEBATE",
    "MUSEO SENADO",
    "GAMER",
    "JM",
    "III FORO DD.HH.",
    "SUPER AUTOS",
    "ALEPH",
    "JUACAS",
    "PARQUE SANITARIO",
  ];

  const mdpeArray = [
    { motion: true, design: false, production: true, experience: true, cliente: "Universidad Nac. de Buenos Aires" },
    { motion: true, design: false, production: true, experience: true, cliente: "Eurolat" },
    { motion: true, design: true, production: false, experience: false, cliente: "OMAVA" },
    { motion: true, design: true, production: false, experience: false, cliente: "CAPIT" },
    { motion: true, design: true, production: true, experience: false, cliente: "Honorable Senado de la Nación" },
    { motion: true, design: true, production: false, experience: false, cliente: "PLANTA ALTA para Flow" },
    { motion: true, design: false, production: true, experience: true, cliente: "Juan Massimo" },
    { motion: true, design: true, production: true, experience: false, cliente: "UNESCO" },
    { motion: true, design: true, production: false, experience: false, cliente: "NIPPUR para Discovery Mexico" },
    { motion: true, design: true, production: true, experience: false, cliente: "Animación independiente" },
    { motion: true, design: true, production: false, experience: false, cliente: "Disney Brasil" },
    { motion: true, design: true, production: true, experience: false, cliente: "Gobierno Provincia Buenos Aires" },
  ];

  const [showVideo, setShowVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [activeSection, setActiveSection] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [infoIndex, setInfoIndex] = useState(null);

  // Función para abrir el video correspondiente al hacer clic en la imagen
  const handleImageClick = (videoSrc, index) => {
    setSelectedVideo(videoSrc);
    setShowVideo(true);
    setInfoIndex(index);
  };

  // Función para cerrar el video
  const closeVideo = () => {
    setSelectedVideo('');
    setShowVideo(false);
    setInfoIndex(null);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  // Función para manejar el mouse saliendo de un cuadrado
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  //UseEffect para cerrar el video al cambiar la sección
  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'contact'];

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && isElementInViewport(element)) {
          closeVideo();
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const visibleTop = rect.top <= windowHeight / 10;
    const visibleBottom = rect.bottom >= windowHeight * 0.9;

    return visibleTop && visibleBottom;
  };

  return (
    <div className="component-container" id="work">
      <div className="work-container">
        {showVideo && (
          <div style={{ flexDirection: 'column' }}>
            <video controls autoPlay loop muted className='video-work'>
              <source src={selectedVideo} type="video/mp4" />
            </video>
            <div className='info-container'>
              <span className="info-title">
                {titles[infoIndex] === "III FORO DD.HH." ? (
                  <>
                    III FORO<br />
                    DD.HH.
                  </>
                ) : (
                  <>
                    {titles[infoIndex].split(' ').map((word, i) => (
                      <React.Fragment key={i}>
                        {i > 0 && <br />}
                        {word}
                      </React.Fragment>
                    ))}
                    {titles[infoIndex].split(' ').length === 1 && <br />}
                    &nbsp;
                  </>
                )}
              </span>

              <div>
                {mdpeArray[infoIndex].motion && (
                  <span className='info-bold'>MOTION</span>
                )}
                {mdpeArray[infoIndex].design && (
                  <span className='info-bold'>/ DESIGN</span>
                )}
                {mdpeArray[infoIndex].production && (
                  <span className='info-bold'>/ PRODUCTION</span>
                )}
                {mdpeArray[infoIndex].experience && (
                  <span className='info-bold'>/ EXPERIENCE</span>
                )}
              </div>
              <span className="info-subtitle">{mdpeArray[infoIndex].cliente}</span>
            </div>
            <button className="button-close" onClick={closeVideo}>x</button>
          </div>
        )}

        {!showVideo && (
          <div className="image-matrix">
            {imagePaths.map((path, index) => (
              <div
                key={index}
                className="image-container"
                onClick={() => handleImageClick(videoPaths[index], index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="image-background" style={{ backgroundImage: `url(${hoveredIndex === index ? path : ''})` }}></div>
                <span className="image-title">{titles[index]}</span>

                {mdpeArray[index].motion && (
                  <div className='item'>
                    <span className='image-bold'>M/ </span><span className="image-subtitle">MOTION</span>
                  </div>
                )}
                {mdpeArray[index].design && (
                  <div className='item'>
                    <span className='image-bold'>D/ </span><span className="image-subtitle">DESIGN</span>
                  </div>
                )}
                {mdpeArray[index].production && (
                  <div className='item'>
                    <span className='image-bold'>P/ </span><span className="image-subtitle">PRODUCTION</span>
                  </div>
                )}
                {mdpeArray[index].experience && (
                  <div className='item'>
                    <span className='image-bold'>E/ </span><span className="image-subtitle">EXPERIENCE</span>
                  </div>
                )}

              </div>
            ))}
          </div>
        )}
        <div className="horizontal-line line-top-left line1"></div>
        <div className="horizontal-line line-top-right line2"></div>
        <div className="horizontal-line line-bottom-right line5"></div>
        <div className="horizontal-line line-bottom-left line6"></div>
        <div className="horizontal-line line-bottom-right line7"></div>
      </div>
    </div>
  );
};
