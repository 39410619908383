import React from 'react';
import '../App.css';

export const Contact = () => {
  return (
    <div className="component-container" id="contact">
      <div className="contact-container">
        <form className="contact-form" action="php-scripts/send_email.php" method="post">
          <input type="email" name="email" placeholder="Email..." className="form-input" required />
          <textarea name="message" placeholder="Type..." className="form-textarea" required></textarea>
          <div className="button-container">
            <button type="submit" className="form-button">SEND</button>
          </div>
        </form>

        <div className='margin-block'>
        <div className='rectangule-row'>
        <div className="social-list">
          <div className="social-icons">
            <div className="social-icon-container">
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <img src={process.env.PUBLIC_URL + '/logotipo-de-instagram.png'} alt="Instagram" className="social-icon" />
                <span className="social-name">Instagram</span>
              </a>
            </div>
            <div className="social-icon-container">
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <img src={process.env.PUBLIC_URL + '/linkedin (2).png'} alt="LinkedIn" className="social-icon" />
                <span className="social-name">LinkedIn</span>
              </a>
            </div>
            <div className="social-icon-container">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <img src={process.env.PUBLIC_URL + '/facebook (1).png'} alt="Facebook" className="social-icon" />
                <span className="social-name">Facebook</span>
              </a>
            </div>
            <div className="social-icon-container">
              <a href="https://vimeo.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <img src={process.env.PUBLIC_URL + '/vimeo (1).png'} alt="Vimeo" className="social-icon" />
                <span className="social-name">Vimeo</span>
              </a>
            </div>
            <div className="social-icon-container">
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <img src={process.env.PUBLIC_URL + '/youtube (1).png'} alt="YouTube" className="social-icon" />
                <span className="social-name">YouTube</span>
              </a>
            </div>
          </div>
        
        </div>
        <div className="rectangle-container">
          <div className="rectangle rectangle1">
            <p><span className="name-text">JULIAN EYZAGUIRRE</span><br />+54 9 11 6468 0525<br />julian@eyestudio.com.ar</p>
          </div>
          <div className="rectangle rectangle2">
            <p><span className="name-text">FLORENCIA YEANNOTEGUY</span><br />+54 9 11 6747 6424<br />florencia@eyestudio.com.ar</p>
          </div>
        </div>
        </div>


        <div className="additional-text">
            <p className="social-email">hola@eyestudio.com.ar</p>
          </div>

          </div>
        
        
       
        <div className="horizontal-line line-top-left line1"></div>
        <div className="horizontal-line line-top-right line2"></div>
        <div className="horizontal-line line-bottom-right line5"></div>
        <div className="horizontal-line line-bottom-left line6"></div>
        <div className="horizontal-line line-bottom-right line7"></div>
      </div>
    </div >
  );
};
