import React from 'react';
import '../App.css';
import videoHome from '../Videos/REELEYOK.mp4';

export const Home = () => {
  return (
    <div className="component-container" id="home">
      <div className="home-container">
        <video controls loop className='video-home'>
          <source src={videoHome} type="video/mp4" />
        </video>
        <div className="horizontal-line line-top-left line1"></div>
        <div className="horizontal-line line-top-right line2"></div>
        <div className="horizontal-line line-bottom-right line5"></div>
        <div className="horizontal-line line-bottom-left line6"></div>
        <div className="horizontal-line line-bottom-right line7"></div>
      </div>
    </div>
  );
};
