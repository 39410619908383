import React from 'react'
import '../App.css';

export const About = () => {
  return (
    <div className="component-container" id="about">
      <div className="about-container">
        <div className="about-section">
          <p className="about-text">
            INVENTAMOS.
            <br />
            PRODUCIMOS.
            <br />
            CONTAMOS.
          </p>
        </div>
        <div className="about-section">
          <p className="about-text2">
            Somos un estudio creativo que trabaja con las nuevas tecnologías y las combinacon una larga trayectoria en el mundo audiovisual y de producción ejecutiva. Construimos, diseñamos y activamos mensajes.
          </p>
        </div>
        <div className="horizontal-line line-top-left line1"></div>
        <div className="horizontal-line line-top-right line2"></div>
        <div className="horizontal-line line-top-right line3"></div>
        <div className="horizontal-line line-bottom-left line4"></div>
        <div className="horizontal-line line-bottom-right line5"></div>
        <div className="horizontal-line line-bottom-left line6"></div>
        <div className="horizontal-line line-bottom-right line7"></div>
      </div>
    </div>
  );
};

